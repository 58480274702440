<template>
  <div class="case">
    <div class="left ty300" ref="transformRef1">
      <div class="img-box">
        <img src="@/assets/images/smart-maintenance-image/page05.png" alt="">
      </div>
      <div class="left-btn curr" @click="prevPage" v-if="options.current !== 1">
        <img src="@/assets/images/operate-image/page15.png" alt="">
      </div>
    </div>
    <div class="w content ty300" ref="transformRef2">
      <div class="item" v-for="item in list" :key="item.id">
        <div class="img-box hvr-grow">
          <img class="x-bgco" :src="item.titlePic" alt="">
        </div>
        <div class="title">{{ item.name }}</div>
        <div class="text" v-html="item.briefText"></div>
        <div class="btn curr" :class="{'active': item === 1}" @click="toDetail(item.id)">查看更多 +</div>
      </div>
      <div class="item no-item" v-for="item in (3 - list.length)" :key="item">
      </div>
      <div class="right-btn curr" @click="nextPage" v-if="options.current * options.size < total">
        <img src="@/assets/images/operate-image/page14.png" alt="">
      </div>

      <div class="phone-item" v-for="item in list2" :key="item.id">
        <div class="img-box">
          <img :src="item.titlePic" alt="">
        </div>
        <div class="title">{{ item.name }}</div>
        <div class="text" v-html="item.briefText"></div>
        <div class="btn curr" :class="{'active': item === 1}" @click="toDetail(item.id)">查看更多 +</div>
      </div>
    </div>
  </div>
</template>

<script>
import apis from '@/request/apis';
import { useRouter } from 'vue-router';
import { reactive, toRefs, watchEffect } from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();

    const data = reactive({
      list: [],
      list2: [],
      options: {
        current: 1,
        size: 3,
      },
      total: 0,
      transformRef1: null,
      transformRef2: null,
      count: 0,
    });
    const toDetail = (id) => {
      router.push({
        name: 'smartMaintenanceDetail',
        params: {
          id,
        },
      });
    };

    const getPage = async () => {
      const res = await apis.receptionClassicCases.getPage({
        ...data.options,
        conditions: JSON.stringify([{ name: 'caseType', opr: 'eq', value: '6' }]),
      });
      const { records, total } = res;
      data.list = records;
      data.total = total;
    };
    const getPage2 = async () => {
      const res2 = await apis.receptionClassicCases.getPage({
        current: 1,
        size: 100,
        conditions: JSON.stringify([{ name: 'caseType', opr: 'eq', value: '6' }]),
      });
      const { records: list2 } = res2;
      data.list2 = list2;
    };
    getPage();
    getPage2();

    const nextPage = async () => {
      if (data.options.current * data.options.size >= data.total) return;
      data.options.current++;
      await getPage();
    };
    const prevPage = async () => {
      if (data.options.current <= 1) return;
      data.options.current--;
      await getPage();
    };

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          y: 0,
          opacity: 1,
          duration: 1.5,
        });
        itemGsap(data.transformRef2, {
          y: 0,
          opacity: 1,
          duration: 1.5,
        });
      }
    });

    return {
      toDetail,
      ...toRefs(data),
      nextPage,
      prevPage,
    };
  },
};
</script>

<style lang="scss" scoped>
.case {
  width: 100vw;
  height: 41.042vw;
  position: relative;
  margin-bottom: 3.125vw;
  margin-top: 3vw;
  .left {
    width: 23.021vw;
    height: 100%;
    background: #064A6C;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    background-image: url('~@/assets/images/operate-image/page33.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% 100%;
    .img-box {
      width: 4.896vw;
      height: 21.302vw;
      margin-top: 3.75vw;
      margin-left: 10.938vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .left-btn {
      width: 3.281vw;
      height: 3.281vw;
      position: absolute;
      top: 19.792vw;
      right: 1.458vw;
      border-radius: 50%;
      z-index: 111;
      &:hover {
        background-color: var(--baseColor1);
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .content {
    display: flex;
    padding-left: 15.417vw;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    translate: all 0.5s;
    position: relative;
    .item {
      width: 19.688vw;
      height: 25.208vw;
      position: relative;
      // border: 1px solid #f3f3f3;
      overflow: hidden;
      .img-box {
        height: 12.604vw;
        width: 100%;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .title {
        height: 1.615vw;
        font-size: 1.25vw;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #064A6C;
        line-height: 1.771vw;
        margin: 1.354vw 0 0.625vw;
        padding: 0 0.521vw;
      }
      .text {
        height: 3.438vw;
        font-size: 0.938vw;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 1.875vw;
        padding: 0 0.521vw;
        overflow: hidden;
      }
      .btn {
        width: 5.521vw;
        height: 2.031vw;
        background-color: #9E835A;
        position: absolute;
        left: 0;
        bottom: 0;
        line-height: 2.031vw;
        text-align: center;
        color: #fff;
        font-size: 0.833vw;
        &:hover {
          background-color: var(--baseColor1);
        }
      }
      &:hover {
        box-shadow: 8px 6px 9px #d6d6d6;
      }
    }
    .phone-item {
      display: none;
    }
    .no-item {
      opacity: 0;
    }
    .right-btn {
      width: 3.281vw;
      height: 3.281vw;
      position: absolute;
      right: -4.896vw;
      top: 19.792vw;
      border-radius: 50%;
      &:hover {
        background-color: var(--baseColor1);
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .case {
    width: 100vw;
    height: 50vw;
    position: relative;
    margin-bottom: 3.125vw;
    .left {
      width: 25vw;
      height: 100%;
      background: #064A6C;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-image: url('~@/assets/images/operate-image/page33.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 100% 100%;
      z-index: 10;
      .img-box {
        width: 8.896vw;
        height: auto;
        margin-top: 3.75vw;
        margin-left: 10.938vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .left-btn {
        display: none;
        width: 3.281vw;
        height: 3.281vw;
        position: absolute;
        top: 19.792vw;
        right: 1.458vw;
        border-radius: 50%;
        z-index: 10;
        &:hover {
          background-color: var(--baseColor1);
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .content {
      overflow-x: scroll;
      display: flex;
      padding-left: 28vw;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      translate: all 0.5s;
      position: relative;
      .item {
        display: none;
      }
      .phone-item {
        width: 45vw;
        flex-shrink: 0;
        height: 40vw;
        position: relative;
        border: 1px solid #f3f3f3;
        margin-right: 2.667vw;
        display: block;
        .img-box {
          height: 20vw;
          width: 100%;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .title {
          height: 4.267vw;
          font-size: 1.867vw;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #064A6C;
          line-height: 4.267vw;
          margin: 1.354vw 0 0.625vw;
          padding: 0 0.521vw;
          padding: 0 1.333vw;
        }
        .text {
          height: 3.2vw;
          font-size: 1.6vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 3.2vw;
          padding: 0 1.333vw;
          overflow: hidden;
        }
        .btn {
          width: 20.521vw;
          height: 6vw;
          background-color: #9E835A;
          position: absolute;
          left: 0;
          bottom: 0;
          line-height: 6vw;
          text-align: center;
          color: #fff;
          font-size: 1.6vw;
          &:hover {
            background-color: var(--baseColor1);
          }
        }
        &:hover {
          box-shadow: 8px 6px 9px #d6d6d6;
        }
      }
      .no-item {
        display: none;
        opacity: 0;
      }
      .right-btn {
        display: none;
        width: 3.281vw;
        height: 3.281vw;
        position: absolute;
        right: -4.896vw;
        top: 19.792vw;
        border-radius: 50%;
        &:hover {
          background-color: var(--baseColor1);
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
