<template>
  <div class="core-advantages">
    <div class="w content ty300" ref="transformRef1">
      <div class="title1">核心优势</div>
      <div class="title2">SERVICE MODULE</div>
      <div class="item-box">
        <div class="left">
          <div class="btn curr" :class="{'active': activeIndex === 0}" @click="activeClick(0)">
            <div class="img-box3">
              <img v-if="activeIndex===0" src="@/assets/images/smart-maintenance-image/page34.png" alt="">
              <img v-else src="@/assets/images/smart-maintenance-image/page33.png" alt="">
            </div>
          </div>
          <div class="btn curr" :class="{'active': activeIndex === 1}" @click="activeClick(1)">
            <div class="img-box3">
              <img v-if="activeIndex===1" src="@/assets/images/smart-maintenance-image/page31.png" alt="">
              <img v-else src="@/assets/images/smart-maintenance-image/page32.png" alt="">
            </div>
          </div>
        </div>
        <div class="right">
          <div class="img-box">
            <img v-if="activeIndex === 0" src="@/assets/images/smart-maintenance-image/page09.png" alt="">
            <img v-else src="@/assets/images/smart-maintenance-image/page45.png" alt="">
          </div>
          <div class="cont" v-if="activeIndex === 0">
            <div class="title">{{ expert.title }}</div>
            <div class="item-text" v-for="(item, index) in expert.list" :key="index">
              <div class="icon">
                <img src="@/assets/images/home-image/30.png" alt="">
              </div>
              <div class="text">{{ item }}</div>
            </div>
          </div>
          <div class="cont" v-else>
            <div class="title">{{ enable.title }}</div>
            <div class="item-text" v-for="(item, index) in enable.list" :key="index">
              <div class="icon">
                <img src="@/assets/images/home-image/30.png" alt="">
              </div>
              <div class="text">{{ item }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="item-box2">
        <div class="top">
          <div class="title">{{ expert.title }}</div>
          <div class="item-text" v-for="(item, index) in expert.list" :key="index">
            <div class="text">{{ index+1 }}. {{ item }}</div>
          </div>
        </div>
        <div class="bottom">
          <div class="title">{{ enable.title }}</div>
            <div class="item-text" v-for="(item, index) in enable.list" :key="index">
              <div class="text">{{index + 1}}. {{ item }}</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      activeIndex: 0,
      transformRef1: null,
      count: 0,
    });
    const expert = {
      title: '专家联盟、技术权威',
      list: [
        '创建冷库故障知识体系，可提供专业培训，帮助客户提高保养知识、维修技术。',
        '多家冷库战略同盟经验累积，建立故障战略库，产出风险行为评估及改造报告。',
        '提供智慧冷库、运行优化等多种服务，为客户智能诊断、给出解决方案。',
      ],
    };

    const enable = {
      title: '数智赋能、人机协同',
      list: [
        '系统远程实时监控、提高灾备预警能力，确保冷库的安全性。',
        '依托大数据分析制定节能技术方案，实现节能降碳运维管理。',
        '售后专家驻场、驻点提供维保服务，做到及时响应、快速解决。',
      ],
    };

    const activeClick = (index) => {
      data.activeIndex = index;
    };

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          y: 0,
          opacity: 1,
          duration: 1.5,
        });
      }
    });

    return {
      expert,
      enable,
      ...toRefs(data),
      activeClick,
    };
  },
};
</script>

<style scoped lang="scss">
.core-advantages {
  width: 100vw;
  height: 40.104vw;
  background-image: url('~@/assets/images/smart-maintenance-image/page13.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  overflow: hidden;
  .title1 {
    height: 4.322vw;
    font-size: 3.333vw;
    font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
    font-weight: 700;
    color: #064A6C;
    line-height: 3.906vw;
    margin-top: 4.688vw;

  }
  .title2 {
    font-size: 1.042vw;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #064A6C;
  }

  .item-box {
    display: flex;
    height: 26.042vw;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .btn {
        width: 13.229vw;
        height: 3.646vw;
        line-height: 3.646vw;
        text-align: center;
        color: #000;
        font-weight: 700;
        margin-bottom: 1.042vw;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-box3 {
          width: 6.4vw;
          height: 1.5625vw;
        }
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
        &.active {
          background: #064A6C;
          color: #fff;
        }
      }
    }
    .right {
      height: 100%;
      flex: 1;
      margin-left: 5.208vw;
      padding: 2.188vw 1.875vw;
      background-color: #fff;
      display: flex;
      .img-box {
        width: 25.208vw;
        height: 21.51vw;
        margin-right: 2.396vw;
        background-color: #fff;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .cont {
        flex: 1;
        padding-top: 2.708vw;
        .title {
          font-size: 1.667vw;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #064A6C;
          line-height: 1.979vw;
          margin-bottom: 2.083vw;
        }
        .item-text {
          display: flex;
          width: 100%;
        }
        .icon {
          width: 0.833vw;
          height: 0.833vw;
          margin-right: 0.417vw;
          transform: translateY(0.625vw);
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .text {
          font-size: 0.938vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 2.083vw;
        }
      }
    }
  }
  .item-box2 {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .core-advantages {
    width: 100vw;
    height: auto;
    background-image: url('~@/assets/images/smart-maintenance-image/page13.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    overflow: hidden;
    .title1 {
      height: 4.322vw;
      font-size: 3.333vw;
      font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
      font-weight: 700;
      color: #064A6C;
      line-height: 3.906vw;
      margin-top: 4.688vw;
      margin-left: 4vw;

    }
    .title2 {
      font-size: 1.042vw;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #064A6C;
      margin-left: 4vw;
    }

    .item-box {
      display: none;
      height: 26.042vw;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .btn {
          width: 13.229vw;
          height: 3.646vw;
          line-height: 3.646vw;
          text-align: center;
          color: #000;
          font-weight: 700;
          margin-bottom: 1.042vw;
          display: flex;
          justify-content: center;
          align-items: center;
          .img-box3 {
            width: 16.267vw;
            height: 4vw;
          }
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
          &.active {
            background: #064A6C;
            color: #fff;
          }
        }
      }
      .right {
        height: 100%;
        flex: 1;
        margin-left: 5.208vw;
        padding: 2.188vw 1.875vw;
        background-color: #fff;
        display: flex;
        .img-box {
          width: 25.208vw;
          height: 21.51vw;
          margin-right: 2.396vw;
          background-color: yellow;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .cont {
          flex: 1;
          padding-top: 2.708vw;
          .title {
            font-size: 1.667vw;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #064A6C;
            line-height: 1.979vw;
            margin-bottom: 2.083vw;
          }
          .item-text {
            display: flex;
            width: 100%;
          }
          .icon {
            width: 0.833vw;
            height: 0.833vw;
            margin-right: 0.417vw;
            transform: translateY(0.625vw);
            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .text {
            font-size: 0.938vw;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 2.083vw;
          }
        }
      }
    }
    .item-box2 {
      display: block;
      padding: 1.333vw;
      .title {
        font-size: 3.2vw;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #064A6C;
        line-height: 1.979vw;
        margin-bottom: 2.083vw;
        margin-left: 2.667vw;
        margin-top: 3.333vw;
      }
      .item-text {
        display: flex;
        width: 100%;
        margin-bottom: 1.333vw;
        font-size: 1.867vw;
      }
      .icon {
        width: 3.2vw;
        height: 3.2vw;
        margin-right: 1.333vw;
        transform: translateY(0.825vw);
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
