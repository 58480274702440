<template>
  <div class="science-and-technology">
    <div class="w content ty300" ref="transformRef1">
      <div class="left"></div>
      <div class="right">
        <div class="title-img">
          <img src="@/assets/images/smart-maintenance-image/page44.png" alt="">
        </div>
        <div class="text text1">
          穗深冷链智慧维保事业部，基于BF-ROM智慧维保系统，致力为客户提供高效精准的售后维保解决方案。目前运维服务涵盖：高标冷仓接近20座，大型中央厨房+预制菜生产基地12座、总运营服务面积超40万㎡。
        </div>
        <div class="text text2">
          提供服务有：远程售后技术支持、8小时到场响应、7天12小时维保工程师驻场等。并基于物联网、大数据、云计算给客户提供一站式维保解决方案。我们将秉持服务至上、科创致远的核心理念，致力成为全国领先的智慧冷链维保服务团队。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      transformRef1: null,
      count: 0,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          y: 0,
          opacity: 1,
          duration: 1.5,
        });
      }
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.science-and-technology {
  width: 100vw;
  height: 37.083vw;
  background-image: url('~@/assets/images/smart-maintenance-image/page04.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  .content {
    display: flex;
    .left {
      flex: 1;
    }
    .right {
      flex: 1;
      .title-img {
        width: 31.51vw;
        height: 5.625vw;
        margin-top: 8.073vw;
        margin-bottom: 3.281vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .text {
        width: 39.844vw;
        font-size: 0.938vw;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 1.875vw;
        text-indent: 1.875vw;
      }
      .text1 {
        margin-bottom: 1.563vw;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .science-and-technology {
    width: 100vw;
    height: auto;
    background-image: url('~@/assets/images/smart-maintenance-image/page04.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    .content {
      display: flex;
      overflow: hidden;
      padding-bottom: 1.333vw;
      .left {
        width: 30%;
        flex: none;
      }
      .right {
        flex: 1;
        padding-right: 1.333vw;
        .title-img {
          width: 50vw;
          height: auto;
          margin-top: 8.073vw;
          margin-bottom: 3.281vw;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .text {
          width: 100%;
          font-size: 1.867vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 4.733vw;
          text-indent: 3.733vw;
        }
        .text1 {
          margin-bottom: 1.563vw;
        }
      }
    }
  }
}
</style>
